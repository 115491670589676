import { defineComponent, ref } from "vue";
import userStore from "../../store/userStore";
import teamStore from "../../store/teamStore";
export default defineComponent({
    props: {
        teamId: {
            type: String,
            required: true
        }
    },
    setup: function (props) {
        var isLoading = ref(true);
        var isCurrentUserMember = ref(false);
        var user = userStore().user;
        var getTeam = teamStore().getTeam;
        var host = location.host;
        var team = ref();
        getTeam(props.teamId).then(function (x) {
            var _a;
            team.value = x;
            if ((_a = team.value) === null || _a === void 0 ? void 0 : _a.memberIds.includes(user.value.id)) {
                isCurrentUserMember.value = true;
            }
            isLoading.value = false;
        });
        return { user: user, team: team, isLoading: isLoading, isCurrentUserMember: isCurrentUserMember, host: host };
    }
});
